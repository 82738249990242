import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FAQ from '../../components/FAQ';
import ServicePageOtherLinks from '../../components/ServicePageOtherLinks';
import ServicePage23WhyChoose from '../../components/ServicePage23WhyChoose';
import Testimonial from '../../components/Testimonial';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

// import Feature from '../../components/Feature'

// import './App.scss';

class ServicesPage2_3_1 extends Component {
  render() {
    return (
      <div className="page homepage">       
        <Banner>
          <h2>Asiantuntijapalvelut</h2>
        </Banner>
        <Container className="intro p-t-100 p-b-50 text-center" id="content">
          <Row className="p-b-100">
            <Col sm={{ span: 8, offset: 2}}>
              <h1>HSE-VALVOJA</h1>
              <h3>KESTÄVÄ JA KEHITTYVÄ TYÖTURVALLISUUDEN KULTTUURI</h3>
              <p>
                HSE-valvojien tehtäviin kuuluvat työterveyden, -turvallisuuden, -ympäristön ja -olosuhteiden valvontatehtävät organisaatiossa. HSE-valvojat suorittavat työn vaarojen ja riskien arviointeja ja kouluttavat organisaation henkilöstöä noudattamaan paikallisia ja kansainvälisiä turvallisuus- ja ympäristömääräyksiä sekä alan standardeja. HSE-valvojat tekevät turvallisuuskierroksia työpaikoilla, työmailla ja teollisuudessa, puuttuvat siellä havaittuihin epäkohtiin, laativat turvallisuushavintoja, -mittauksia ja raportteja sekä toimivat asiantuntijoina turvallisuusasioissa.
              </p>

              <h4>Miksi HSE-valvojat ovat tärkeitä?</h4>

              <p className="p-b-50">
                HSE-valvojat parantavat ja kehittävät omalla vastuu alueellaan turvallisuutta ja turvallisuuskulttuuria yhteisellä työpaikalla, työmailla ja teollisuudessa. HSE-valvojien toiminnassa tähdätään turvallisuutta ja hyvinvointia parantaviin vaikutuksiin, mikä parhaimmillaan suojelee ihmisiä onnettomuuksilta ja tapaturmilta sekä organisaatiota toiminnan keskeytyksiltä.
              </p>

              <Link className="btn-grey" to="/tajouspyynto">PYYDÄ TARJOUS</Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="p-b-25 p-t-25 bg-gray text-center">
          <Row>
            <Col>
              <h2>Myös he luottavat meihin turvallisuusasioissa:</h2>
              <NewReferences references={['noho', 'finlandia', 'oldIrish', 'jokerit', 'rajupaja', 'allas', 'vanha', 'jt']} />
            </Col>
          </Row>
        </Container>

        <Container className="p-t-50 p-b-50">
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h3 className="p-b-25">Palvelun kuvaus</h3>
              <h4>HSE-valvonta</h4>
              <p>Työterveyden, -turvallisuuden, -ympäristön ja -olosuhteiden valvominen ja kehittäminen. Paikallisten ja kansainvälisten turvallisuus- ja ympäristömääräysten toteutumisen valvominen.</p>

              <h4>Vaarojen ja riskien tunnistaminen ja arviointi</h4>
              <p>Työtoimenpiteiden vaarojen ja riskien tunnistaminen ja arviointi. Henkilöstön ohjeistaminen oikeiden työvälineiden ja henkilösuojainten käyttöön.</p>

              <h4>Koulutus</h4>
              <p>Henkilöstön ohjeistaminen ja kouluttaminen työterveyteen, -turvallisuuteen, -ympäristöön ja olosuhteisiin liittyvissä asioissa. Toolbox/Workshop tyylisten säännöllisten koulutusten järjestäminen.</p>

              <h4>Konsultointi</h4>
              <p>Organisaation ja henkilöstön konsultointi HSE asioissa.</p>

              <h4>Raportointi</h4>
              <p>Raportointi turvallisuusorganisaatiolle turvallisuushavainnoista, -kierroksista ja koulutuksista sovitun käytännön mukaisesti.</p>
            </Col>
          </Row>
        </Container>

        <ServicePageOtherLinks>
          <Link to="/vartiointi/tyomaajateollisuusvartiointi">Työmaa- ja teollisuusvartiointi</Link>
          <Link to="/asiantuntijapalvelut/turvallisuusvalvoja">Turvallisuusvalvoja</Link>
          <Link to="/muutpalvelut/defibrillaattori">Defibrilaattorit</Link>
          <Link to="/muutpalvelut/turvatekniikka">Turvatekniikka</Link>
        </ServicePageOtherLinks>

        <ServicePage23WhyChoose />

        <Testimonial />

        <FAQ/>

        <ServicePageForm />
      </div>
    );
  }
}

export default ServicesPage2_3_1;
